<template>
	<div class="sycm-page">
		<div class="page-section page-head">
			<div class="section-head">
				<div class="title">{{$t('sycm.lives.storeGoodsDeal')}}</div>
				<div class="action">
					<ul>
						<li>
							<el-date-picker v-model="marketDate" type="date" size="small" value-format="timestamp"
								:placeholder="$t('liveplatform.placeholder.date')" :picker-options="pickerOptions" @change="handleMarketDateChange">
							</el-date-picker>
						</li>
					</ul>
				</div>
			</div>
			<div class="head-wrap">
				<div class="item" v-if="overview.transaction_price">
					<div class="name">
						<span>{{$t('sycm.common.plantOrderMoney')}}</span>
						<el-popover width="300" trigger="hover" :content="$t('sycm.popover.plantOrderMoney1')">
							<i slot="reference" class="el-icon-question"></i>
						</el-popover>
					</div>
					<div class="number"><span>{{overview.transaction_price.values}}</span> 元</div>
					<div class="text">
						<span>{{$t('sycm.lives.meanValue')}} {{overview.transaction_price.comp_values || '-'}}</span>
						<el-popover width="300" trigger="hover" :content="$t('sycm.popover.livePlantGrass')">
							<i slot="reference" class="el-icon-question"></i>
						</el-popover>
					</div>
				</div>
				<div class="item" v-if="overview.transaction_rate">
					<div class="name">
						<span>{{$t('sycm.common.plantOrderRate')}}</span>
						<el-popover width="300" trigger="hover" :content="$t('sycm.popover.plantOrderRate1')">
							<i slot="reference" class="el-icon-question"></i>
						</el-popover>
					</div>
					<div class="number"><span>{{overview.transaction_rate.values}}</span> %</div>
					<div class="text">
						<span>{{$t('sycm.lives.meanValue')}} {{overview.transaction_price.comp_values || '-'}}</span>
						<el-popover width="300" trigger="hover" :content="$t('sycm.popover.livePlantGrass')">
							<i slot="reference" class="el-icon-question"></i>
						</el-popover>
					</div>
				</div>
				<div class="item" v-if="overview.new_visitors">
					<div class="name">
						<span>{{$t('sycm.common.liveStoreNewCustomer')}}</span>
						<el-popover width="300" trigger="hover" :content="$t('sycm.popover.liveStoreNewCustomer1')">
							<i slot="reference" class="el-icon-question"></i>
						</el-popover>
					</div>
					<div class="number"><span>{{overview.new_visitors.values}}</span> 人</div>
					<div class="text">
						<span>{{$t('sycm.lives.meanValue')}} {{overview.new_visitors.comp_values || '-'}}</span>
						<el-popover width="300" trigger="hover" :content="$t('sycm.popover.livePlantGrass')">
							<i slot="reference" class="el-icon-question"></i>
						</el-popover>
					</div>
				</div>
			</div>
		</div>
		<div class="page-section page-market">
			<div class="list-head">
				<div class="head-tabs">
					<ul>
						<li v-for="(item,index) in $t('sycm.options.livePlantGrass')" :key="index" :class="{active: activeTabs == item.value}">
							<span>{{item.label}}</span>
							<el-popover width="300" trigger="hover" :content="item.tip">
								<i slot="reference" class="el-icon-question"></i>
							</el-popover>
						</li>
					</ul>
				</div>
				<div class="head-bar">
				</div>
			</div>
			<div class="section-head">
				<div class="title">
					<div>{{$t('sycm.lives.shopGoodsDeal')}}</div>
				</div>
				<div class="action">
				</div>
			</div>
			<div class="market-swiper">
				<swiper ref="mySwiper" :options="swiperOptions">
					<swiper-slide v-for="(item, index) in $t('sycm.options.marketSwiper')" :key="index">
						<div class="field-item">
							<!-- :class="{active:marketIndex==index}" -->
							<div class="item-label">
								<span class="mr10">{{ item.label }}</span>
								<el-popover v-if="item.tip" width="300" trigger="hover" :content="item.tip">
									<i slot="reference" class="el-icon-question"></i>
								</el-popover>
							</div>
							<div class="item-value">
								{{ marketPanel[item.value] * 1 > 0 ? marketPanel[item.value] : '-' }}
							</div>
						</div>
					</swiper-slide>
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
				</swiper>
			</div>

			<div class="market-thead">
				<div class="name"></div>
				<div class="action">
					<ul>
						<li>
							<el-radio-group v-model="marketRadio" size="small" @change="handleMarketRadioChange">
								<el-radio-button :label="7">{{ $t('liveplatform.data.day7') }}</el-radio-button>
								<el-radio-button :label="15">{{ $t('liveplatform.data.day15') }}</el-radio-button>
								<el-radio-button :label="30">{{ $t('liveplatform.data.day30') }}</el-radio-button>
							</el-radio-group>
						</li>
						<li>
							<el-date-picker v-model="marketDaterange" type="daterange" size="small"
								value-format="timestamp" range-separator="-"
								:start-placeholder="$t('liveplatform.placeholder.startDate')"
								:end-placeholder="$t('liveplatform.placeholder.endDate')"
								:picker-options="pickerOptions" @change="handleMarketDaterangeChange">
							</el-date-picker>
						</li>
						<li class="type">
							<!-- <span :class="{active:tableType==1}" @click="handleTableTypeClick(1)">{{$t('liveplatform.data.chart')}}</span> | -->
							<span :class="{ active: tableType == 2 }"
								@click="handleTableTypeClick(2)">{{ $t('liveplatform.data.table') }}</span>
						</li>
						<!-- <li>
							<el-button type="info" size="small" @click="exportMarketList"><i
									class="el-icon-download"></i>{{ $t('liveplatform.data.download') }}</el-button>
						</li> -->
					</ul>
				</div>
			</div>
			<div v-show="tableType == 1">
				<div id="myChart" style="height: 400px;width: 1000px;margin: 0 auto;"></div>
			</div>
			<div v-show="tableType == 2">
				<div class="page-table">
					<el-table :data="marketList" size="small" style="width: 100%">
						<el-table-column prop="create_time" :label="$t('sycm.tableHead.countTime')" min-width="140px"
							fixed>
						</el-table-column>
						<el-table-column prop="price" :label="$t('sycm.common.plantOrderMoney')"
							min-width="140px">
						</el-table-column>
						<el-table-column prop="trans_rate" :label="$t('sycm.common.plantOrderRate')"
							min-width="140px">
						</el-table-column>
						<el-table-column prop="new_persons" :label="$t('sycm.common.liveStoreNewCustomer')"
							min-width="140px">
						</el-table-column>
						<el-table-column prop="new_fans" :label="$t('sycm.tableHead.liveNewFans')"
							min-width="140px">
						</el-table-column>
						<el-table-column prop="trans_persons" :label="$t('sycm.tableHead.plantDealPeople')"
							min-width="140px">
						</el-table-column>
						<el-table-column prop="trans_num" :label="$t('sycm.tableHead.plantDealNumber')"
							min-width="140px">
						</el-table-column>
						<el-table-column prop="order_num" :label="$t('sycm.tableHead.plantDealCount')"
							min-width="140px">
						</el-table-column>
						<el-table-column prop="goods_visitors" :label="$t('sycm.tableHead.goodsClickPeople')"
							min-width="140px">
						</el-table-column>
						<el-table-column prop="goods_views" :label="$t('sycm.tableHead.goodsClickNumber')"
							min-width="140px">
						</el-table-column>
						<el-table-column prop="goods_into_cart_persons" :label="$t('sycm.tableHead.goodsPlusPeople')"
							min-width="140px">
						</el-table-column>
						<el-table-column prop="goods_into_cart_num" :label="$t('sycm.tableHead.goodsPlusNumber')"
							min-width="140px">
						</el-table-column>
					</el-table>
				</div>
				<div class="page-bottom line-bottom">
					<el-pagination @size-change="handleMarketSizeChange" @current-change="handleMarketCurrentChange"
						:current-page.sync="marketPageCurr" :page-size="marketPageSize"
						layout="prev, pager, next" :prev-text="$t('liveplatform.pagination.prevText')"
						:next-text="$t('liveplatform.pagination.nextText')" :total="marketPageTotal">
					</el-pagination>
				</div>
			</div>
			<div class="section-head">
				<div class="title">{{$t('sycm.lives.shopGoodsTimes')}}</div>
				<div class="action">
					<ul>
						<li>
							<el-select v-model="goodsScreen.live_id" size="small" clearable
								:placeholder="$t('liveplatform.placeholder.selectTimes')"
								@change="handleGoodsScreenChange">
								<el-option v-for="(item, index) in goodsTimes" :key="index"
									:label="'[' + formatDate(item.start_time) + '] ' + item.title" :value="item.live_id">
								</el-option>
							</el-select>
						</li>
						<li>
							<el-input v-model="goodsScreen.title"
								:placeholder="$t('liveplatform.placeholder.goodsTitleID')" size="small"
								@change="handleGoodsScreenChange">
								<i class="el-icon-search el-input__icon" slot="suffix">
								</i>
							</el-input>
						</li>
						<!-- <li>
							<el-button type="info" size="small" @click="exportGoodsList"><i
									class="el-icon-download"></i>{{ $t('liveplatform.data.download') }}</el-button>
						</li> -->
					</ul>
				</div>
			</div>
			<div class="page-tip">
				<div class="text"></div>
				<div class="bar">{{ $t('liveplatform.data.more') }}</div>
			</div>
			<div class="page-table">
				<el-table :data="goodsList" size="small">
					<el-table-column :label="$t('sycm.tableHead.goodsInfo')" min-width="200px" fixed>
						<template slot-scope="scope">
							<div class="goods-item">
								<img class="pic" :src="scope.row.goods_image" @error="imageErrorGoods" alt="">
								<div class="item-main">
									<div class="name">{{ scope.row.goods_name }}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="goods_id" :label="$t('sycm.tableHead.goodsId')" min-width="140px">
					</el-table-column>
					<el-table-column prop="user_name" :label="$t('sycm.tableHead.userName')" min-width="140px">
					</el-table-column>
					<el-table-column prop="title" :label="$t('sycm.tableHead.liveTitle')" min-width="140px">
					</el-table-column>
					<el-table-column prop="live_id" :label="$t('sycm.tableHead.timesId')" min-width="140px">
					</el-table-column>
					<el-table-column :label="$t('sycm.tableHead.broadcastTime')" min-width="140px">
						<template slot-scope="scope">
							{{ scope.row.settime | parseTime() }}
						</template>
					</el-table-column>
					<el-table-column prop="goods_visitors" :label="$t('sycm.tableHead.goodsClickPeople')"
						min-width="140px">
					</el-table-column>
					<el-table-column prop="goods_views" :label="$t('sycm.tableHead.goodsClickNumber')"
						min-width="140px">
					</el-table-column>
					<el-table-column prop="goods_into_cart_persons" :label="$t('sycm.tableHead.goodsPlusPeople')"
						min-width="140px">
					</el-table-column>
					<el-table-column prop="goods_into_cart_num" :label="$t('sycm.tableHead.goodsPlusNumber')"
						min-width="140px">
					</el-table-column>
					<el-table-column prop="trans_persons" :label="$t('sycm.tableHead.plantDealPeople')"
						min-width="140px">
					</el-table-column>
					<el-table-column prop="order_num" :label="$t('sycm.tableHead.plantDealCount')"
						min-width="140px">
					</el-table-column>
					<el-table-column prop="trans_price" :label="$t('sycm.common.plantOrderMoney')"
						min-width="140px">
					</el-table-column>
				</el-table>
			</div>

			<div class="page-bottom">
				<el-pagination @size-change="handleGoodsSizeChange" @current-change="handleGoodsCurrentChange"
					:current-page.sync="goodsPageCurr" :page-size="goodsPageSize" layout="prev, pager, next"
					:prev-text="$t('liveplatform.pagination.prevText')"
					:next-text="$t('liveplatform.pagination.nextText')" :total="goodsPageTotal">
				</el-pagination>
			</div>
		</div>
		<div class="page-section page-tabs"></div>
	</div>
</template>

<script>
import vueExcel from 'xlsx'
import { parseTime } from '@/utils/index'
import { fetchLivesMarketOverview, fetchLivesMarketInfo, fetchLivesMarketList, fetchLivesMarketTimes, fetchLivesMarketGoods } from "@/api/sycm"

export default {
	data() {
		return {
			pickerOptions: {
				disabledDate(time) {
					const today = new Date()
					today.setMonth(today.getMonth()-3)
					return time.getTime()+86400000 > Date.now() || time.getTime() < today.getTime() - 86400000;
				},
				firstDayOfWeek: 1
			},
			overview: {},
			activeTabs: '1',
			swiperOptions: {
				// loop: true,
				slidesPerView: 5,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			},
			marketDate: new Date(new Date().toLocaleDateString()).getTime()-86400000,
			marketPanel: {},
			marketRadio: 15,
			marketDaterange: '',
			tableType: 2,
			marketList: [],
			marketPageCurr: 1,
			marketPageSize: 10,
			marketPageTotal: 0,
			// 本店商品直播间成交
			goodsScreen: {
				live_id: '',
				title: ''
			},
			goodsTimes: [],
			goodsList: [],
			goodsPageCurr: 1,
			goodsPageSize: 10,
			goodsPageTotal: 0
		}
	},
	created() {
		if (this.marketDate) {
			let startTime = this.marketDate - 86400000 * this.marketRadio;
			this.marketDaterange = [startTime, this.marketDate];
		}
		this.getDate()
	},
	methods: {
		imageErrorGoods(e) {
			e.target.src = this.$imageErrorGoods;
			e.target.style.objectFit = 'fill';
		},
		formatDate(time) {
			return parseTime(time)
		},
		async getDate(){
			const loading = this.$loading()
			try {
				const params = {
					time_from: parseInt(this.marketDate / 1000),
					time_to: parseInt(this.marketDate / 1000) + 86399
				}
				const { data } = await fetchLivesMarketOverview(params)
				this.overview = data

				const timesParams = {
					time_from: parseInt(this.marketDate / 1000),
					time_to: parseInt(this.marketDate / 1000) + 86399
				}
				const times =  await fetchLivesMarketTimes(timesParams)
				this.goodsTimes = times.data.list || []
				this.getMarketInfo()
				this.getMarketList()
				this.getGoodsList()
			} catch (error) {
				console.log(error)
			}
			loading.close()
		},
		getMarketInfo() {
			const params = {
				time_from: parseInt(this.marketDate / 1000),
				time_to: parseInt(this.marketDate / 1000) + 86399
			}
			fetchLivesMarketInfo(params).then(response => {
				const { data } = response
				this.marketPanel = data || {}
			})
		},
		getMarketList() {
			const params = {
				time_from: parseInt(this.marketDaterange[0] / 1000),		
				time_to: parseInt(this.marketDaterange[1] / 1000),
				page_index: (this.marketPageCurr - 1) * this.marketPageSize,
				page_size: this.marketPageSize,
			}
			fetchLivesMarketList(params).then(response => {
				const { data } = response
				this.marketList = data.list
				this.marketPageTotal = parseInt(data.count)
			})
		},
		handleMarketSizeChange(val) {
			this.marketPageSize = val
			this.getMarketList()
		},
		handleMarketCurrentChange(val) {
			this.marketPageCurr = val
			this.getMarketList()
		},
		handleMarketRadioChange(val) {
			if (this.marketDate) {
				let startTime = this.marketDate - 86400000 * parseInt(val);
				this.marketDaterange = [startTime, this.marketDate];
			}
			this.marketPageCurr = 1
			this.getMarketList()
		},
		handleMarketDateChange() {
			if (this.marketDate) {
				let startTime = this.marketDate - 86400000 * parseInt(this.marketRadio);
				this.marketDaterange = [startTime, this.marketDate];
				this.marketPageCurr = 1
				this.goodsPageCurr = 1
				this.getDate()
			}
		},
		handleMarketDaterangeChange() {
			if (this.marketDaterange) {
				this.marketPageCurr = 1
				this.getMarketList()
			}
		},
		handleTableTypeClick(val) {},
		exportMarketList() {},
		// 本店商品直播间成交
		getGoodsList() {
			const params = {
				...this.goodsScreen,
				page_index: (this.goodsPageCurr - 1) * this.goodsPageSize,
				page_size: this.goodsPageSize
			}
			fetchLivesMarketGoods(params).then(response => {
				const { data } = response
				this.goodsList = data.list
				this.goodsPageTotal = parseInt(data.count)
			})
		},
		handleGoodsSizeChange(val) {
			this.goodsPageSize = val
			this.getGoodsList()
		},
		handleGoodsCurrentChange(val) {
			this.goodsPageCurr = val
			this.getGoodsList()
		},
		handleGoodsScreenChange() {
			this.goodsPageCurr = 1
			this.getGoodsList()
		},
		exportGoodsList() {},
		// 导出通用
		excelExport(tableData, fileName) {
			let ws = vueExcel.utils.aoa_to_sheet(tableData);
			let wb = vueExcel.utils.book_new();
			vueExcel.utils.book_append_sheet(wb, ws, fileName);
			vueExcel.writeFile(wb, fileName + '.xlsx');
		},
	}
}
</script>

<style lang="scss" scoped>
.page-section {
	border-radius: 6px;
	background-color: #FFFFFF;
	margin-bottom: 20px;
	box-shadow: 0 2px 6px 0 rgb(0 0 0 / 8%);

	.section-head {
		display: flex;
		padding: 20px 30px 10px;
		align-items: center;
		justify-content: space-between;

		.title {
			font-size: 14px;
			color: #333333;
			line-height: 24px;
		}

		.action {
			li {
				color: #999999;
				display: inline-block;
				margin-left: 15px;
			}

			li:first-child {
				margin-left: 0;
			}

			.type {
				color: #f0f0f0;

				span {
					color: #C0C0C0;
					font-size: 12px;
					padding: 0 10px;
					cursor: pointer;
				}

				span.active {
					color: $--sycm-primary;
				}
			}

			.el-button--info {
				color: #333333;
				border-color: #c8c8c8;
				background-color: #FFFFFF;
			}

			.el-button--info:hover {
				background-color: #f9f9f9;
			}
		}
	}
}

.page-head {
	.head-wrap {
		padding: 15px 30px;
		display: flex;

		.item {
			width: 170px;
			line-height: 1.5;

			.name {
				font-size: 14px;
				color: #333333;
			}

			.number {
				font-size: 12px;
				padding: 10px 0 5px;

				span {
					font-size: 26px;
				}
			}

			.text {
				font-size: 12px;
				color: #999999;
			}

			.el-popover__reference {
				margin-left: 4px;
			}
		}
	}
}

.page-tabs {
	::v-deep .el-tabs__header {
		border-radius: 6px;
		background-color: #FFFFFF;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .08);
		margin-bottom: 20px;

		.el-tabs__nav-wrap {
			padding: 0 30px;
		}

		.el-tabs__nav-wrap:after {
			background-color: transparent;
		}

		.el-tabs__item {
			height: 48px;
			line-height: 48px;
			color: $--sycm-primary;
		}

		.el-tabs__active-bar {
			background-color: $--sycm-primary;
		}
	}
}

.page-market {
	.market-swiper {
		padding: 0 24px;
		position: relative;

		.swiper-container {
			overflow: hidden;
		}

		.swiper-button-prev,
		.swiper-button-next {
			color: #333333;
			opacity: .6;
		}

		.swiper-button-prev:after,
		.swiper-button-next:after {
			font-size: 16px;
		}

		.swiper-button-prev {
			left: 0;
		}

		.swiper-button-next {
			right: 0;
		}

		.swiper-button-disabled {
			opacity: 0;
		}

		.field-item {
			padding: 14px 19px;
			margin: 0 5px;
			height: 65px;
			cursor: pointer;
			border: 1px solid #f9f9f9;
			background-color: #f9f9f9;

			.item-label {
				color: #333333;
				font-size: 14px;
				line-height: 20px;
				white-space: nowrap;

				.mr10 {
					margin-right: 10px;
				}

				.el-icon-question {
					color: #D9D9D9;
					font-size: 16px;
				}
			}

			.item-value {
				height: 34px;
				line-height: 34px;
				margin-top: 10px;
				font-size: 26px;
				color: #333333;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.field-item.active {
			border-color: $--sycm-primary;
		}
	}

	.market-thead {
		display: flex;
		padding: 20px 30px;
		justify-content: space-between;
		align-items: center;

		.name {
			font-size: 14px;
			color: #666666;
		}

		.action {
			li {
				display: inline-block;
				margin-left: 15px;
			}

			li:first-child {
				margin-left: 0;
			}

			.type {
				color: #f0f0f0;

				span {
					color: #C0C0C0;
					font-size: 12px;
					padding: 0 10px;
					cursor: pointer;

					&.active {
						color: $--sycm-primary;
					}
				}
			}
		}

		::v-deep .el-radio-group {
			.el-radio-button {
				.el-radio-button__inner {
					color: #c8c8c8;
					border-color: #c8c8c8;
				}

				.el-radio-button__orig-radio:checked+.el-radio-button__inner {
					background-color: #f9f9f9;
					box-shadow: -1px 0 0 0 #c8c8c8;
				}
			}
		}

		.el-button--info {
			color: #333333;
			border-color: #c8c8c8;
			background-color: #FFFFFF;
		}

		.el-button--info:hover {
			background-color: #f9f9f9;
		}
	}
}

.page-tip {
	display: flex;
	padding: 10px 30px;
	line-height: 12px;
	justify-content: space-between;

	div {
		font-size: 12px;
		color: #999999;
	}
}

.page-table {
	margin: 0 30px 30px;

	::v-deep .el-table {

		.el-table__fixed {
			height: 100% !important;
		}
	}

	.primary {
		color: $--sycm-primary;
	}

	.goods-item {
		display: flex;
		align-items: center;
		justify-content: center;

		.pic {
			width: 45px;
			height: 45px;
			object-fit: cover;
			margin-right: 12px;
		}

		.item-main {
			width: 0;
			flex: 1;

			.name {
				max-height: 36px;
				color: $--sycm-primary;
				font-size: 12px;
				line-height: 18px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				cursor: pointer;
			}
		}
	}
}

.list-head {
	text-align: center;
	position: relative;
	min-height: 40px;
	padding: 40px 30px 0;

	.head-tabs {
		width: 160px;
		border-radius: 20px;
		border: 1px solid $--sycm-primary;
		box-sizing: border-box;
		display: inline-block;
		overflow: hidden;

		ul {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		li {
			color: $--sycm-primary;
			font-size: 14px;
			flex-shrink: 0;
			width: 100%;
			height: 38px;
			line-height: 38px;
			text-align: center;
			background-color: $--sycm-primary;
			cursor: pointer;
		}

		li.active {
			color: #FFFFFF;
			background-color: $--sycm-primary;
		}
	}

	.head-bar {
		top: 0;
		right: 30px;
		position: absolute;
		color: #999999;
		padding-top: 4px;

		.bar-tit {
			span {
				font-size: 12px;
				margin-right: 10px;
			}
		}

		.bar-tip {
			text-align: right;
			line-height: 1em;
			margin-top: 8px;
			font-size: 12px;
		}
	}
}

.page-bottom {
	margin: 0 30px;
	padding-bottom: 30px;
	text-align: right;

	&.line-bottom {
		border-bottom: 1px solid #f5f5f5;
	}
}
</style>
